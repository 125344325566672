/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { Injectable } from '@angular/core'
import { LoadingController, ModalController } from '@ionic/angular/standalone'
import { CacheService } from './cache.service'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { Utils } from '../../../../src/commons/utils/utils'
import { HtmlContentModalComponent } from '../../../../src/commons/html-content-modal/html-content-modal.component'
import { ContactUsComponent } from '../../../../src/commons/contact-us/contact-us.component'
import { StatusEnum } from '../../../../src/app/card/activate/models/card-status.enum'
import { Card } from '../../../../src/app/card/activate/models/card.model'
import { AddCardToWalletComponent } from '../../../../src/commons/add-card-to-wallet/add-card-to-wallet.component'
import { GoogleAnalyticsService } from 'ngx-google-analytics'
import { ApiService } from '../api/api.service'
import { NotificationService } from '../ui/notification.service'
import { ActionButton, CustomAlertComponent } from '../../../../src/commons/custom-alert/custom-alert.component'
import { CRBSettingKeys, InfoActionMenuItems } from '../api/models/configuration.model'
import { NotificationManagementComponent } from '../../../../src/commons/notification-management/notification-management.component'

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private googleAnalyticsCategory = 'navigation_menu'
    loading: HTMLIonLoadingElement

    constructor(public modalController: ModalController,
        private cacheService: CacheService,
        private router: Router,
        private apiService: ApiService,
        private notificationService: NotificationService,
        private loadingController: LoadingController,
        private readonly translate: TranslateService,
        protected $gaService: GoogleAnalyticsService,) {
        CacheService.brandConfigurationSettingsChanged.subscribe(() => {
            console.log('entrou')
            this.loadMenus()
        })
    }

    loadMenus(): void {
        this.buildInfoMenu()
        this.buildManageMenu()
    }

    public menus: Array<SlpMenu> = [
        {
            label: this.translate.instant('commons.menu.home') as string,
            route: '/home'
        },
        {
            label: this.translate.instant('commons.menu.manage') as string,
            route: '',
            active: false
        },
        {
            label: this.translate.instant('commons.menu.statements') as string,
            route: '/statement',
            active: false
        },
        {
            label: this.translate.instant('commons.menu.info') as string,
            route: '',
            active: false
        },
        {
            label: this.translate.instant('commons.menu.contactUs') as string,
            route: '',
            action: (): void => void this.openContactUs(),
            active: false
        }
    ]

    menuClicked(item: SlpMenu): void {
        if (item.route) {
            void this.router.navigate([item.route])
        } else {
            item.action()
        }
    }

    buildInfoMenu(): void {
        const infoActionItems = this.cacheService.getBrandConfigSettings().filter(q => InfoActionMenuItems.indexOf(q.keyName) >= 0)
        const infoMenu = this.menus.find(q => q.label == this.translate.instant('commons.menu.info'))
        infoMenu.children = []

        const newMenuItems: SlpMenu[] = []
        for (let i = 0; i < infoActionItems.length; i++) {
            const actionItem = infoActionItems[i]
            const labelText = this.translate.instant(`commons.menu.${Utils.camelize(actionItem.keyName)}`) as string
            const newMenuItem = {
                label: labelText,
                route: null,
                action: () => this.displayModal(labelText, actionItem.keyValue)
            } as SlpMenu
            newMenuItems.push(newMenuItem)
        }

        //ESignDisclosure
        const eSignDisclosureMenuItem = {
            label: this.translate.instant('commons.menu.eSignDisclosure') as string,
            route: null,
            action: () => this.displayModal('eSignDisclosure', 'https://www.viewaccount.com/esignaturedisclosure')
        } as SlpMenu
        newMenuItems.push(eSignDisclosureMenuItem)

        infoMenu.children = infoMenu.children.concat(newMenuItems)
    }

    buildManageMenu(): void {
        const settings = this.cacheService.getBrandConfigSettings()
        const isTransferToBankEnabled = (settings?.find(q => q.keyName === CRBSettingKeys.IsTransferToBankEnabled)?.keyValue.toLowerCase() === 'true') || false

        const manageMenu = this.menus.find(q => q.label == this.translate.instant('commons.menu.manage'))
        manageMenu.children = []

        const newMenuItems: SlpMenu[] = []

        if (isTransferToBankEnabled) {
            newMenuItems.push({
                label: this.translate.instant('commons.menu.bankTransferEnrollment') as string,
                route: '/bank-transfer-enrollments',
                active: false
            } as SlpMenu)
        }

        if (this.hasAnyCardAbleToBeActivated()) {
            const newMenuItem = {
                label: this.translate.instant('commons.menu.activateCard') as string,
                route: '/card/activate',
            } as SlpMenu
            newMenuItems.push(newMenuItem)
        }

        if (this.isIntraFiEnabled()) {
            const newMenuItem = {
                label: this.translate.instant('commons.menu.intraFi') as string,
                route: null,
                action: () => this.router.navigate(['intra-fi'])
            } as SlpMenu
            newMenuItems.push(newMenuItem)
        }

        if (this.hasActiveCards()) {
            const newMenuItem = {
                label: this.translate.instant('commons.menu.resetPin') as string,
                route: null,
                action: () => this.router.navigate(['card/pin'], {
                    queryParams: { from: 'menu' }
                })
            } as SlpMenu
            newMenuItems.push(newMenuItem)
        }

        const account = this.cacheService.getSelectedAccount()
        if (account.cardStatus == 'Open' && Utils.isUsingAndroidOrIos()) {

            const newMenuItem = {
                route: '',
                action: (): void => void this.openAddToWallet(),
                active: false
            } as SlpMenu

            newMenuItem.label = Utils.detectMobileOS() === 'iOS' ?
                this.translate.instant('commons.menu.addCardToAppleWallet') as string :
                this.translate.instant('commons.menu.addCardToGoogleWallet') as string

            newMenuItems.push(newMenuItem)
        }

        newMenuItems.push({
            route: '',
            label: this.translate.instant('commons.menu.notifications') as string,
            action: (): void => void this.openNotificationManagement(),
            active: false
        } as SlpMenu)

        manageMenu.children = manageMenu.children.concat(newMenuItems)
    }

    async displayModal(item: string, url: string): Promise<void> {
        const linkModal = await this.modalController.create({
            component: HtmlContentModalComponent,
            componentProps: {
                modalHtmlUrl: url
            },
            cssClass: 'html-modal',
        })
        //register GA event
        this.$gaService.event(`view_${item.replace(' ', '_').toLowerCase()}`, this.googleAnalyticsCategory)
        await linkModal.present()
    }

    async openContactUs(): Promise<void> {
        const modal = await this.modalController.create({
            component: ContactUsComponent,
            cssClass: 'common-modal'
        })
        //register GA event
        this.$gaService.event('view_contactus', this.googleAnalyticsCategory)
        return modal.present()
    }

    async openNotificationManagement(): Promise<void> {
        const modal = await this.modalController.create({
            component: NotificationManagementComponent,
            cssClass: 'common-modal'
        })
        //register GA event
        this.$gaService.event('view_notification_management', this.googleAnalyticsCategory)
        return modal.present()
    }

    async openAddToWallet(): Promise<void> {
        const account = this.cacheService.getSelectedAccount()

        const modal = await this.modalController.create({
            component: CustomAlertComponent,
            cssClass: 'custom-alert',
            componentProps: {
                headerText: Utils.detectMobileOS() === 'iOS' ?
                    this.translate.instant('commons.menu.addCardToAppleWallet') as string :
                    this.translate.instant('commons.menu.addCardToGoogleWallet') as string,
                messageText: this.translate.instant('commons.link2wallet.addCardToWalletMessage', { program: account.programName, phoneType: Utils.detectMobileOS() === 'iOS' ? 'Apple' : 'Android' }) as string,
                actionButtons: [
                    {
                        color: 'light',
                        text: this.translate.instant('commons.link2wallet.cancel') as string
                    } as ActionButton,
                    {
                        action:
                            () => this.getLinkToAddToWallet(),
                        text: this.translate.instant('commons.link2wallet.getStarted') as string
                    } as ActionButton]
            }
        })
        //register GA event
        this.$gaService.event('view_add_to_wallet', this.googleAnalyticsCategory)
        void modal.present()
    }

    async getLinkToAddToWallet(): Promise<void> {
        try {
            void await this.presentLoading()
            const link2Wallet = await this.apiService.getLink2Wallet()

            const modal = await this.modalController.create({
                component: AddCardToWalletComponent,
                cssClass: 'common-modal',
                componentProps: {
                    tokenlink: link2Wallet.tokenizedLink
                }
            })

            void modal.present()
        } catch (e) {
            if (e.error && e.error.error)
                void this.notificationService.showToasterErrorMessage(e.error.error)
            else
                void this.notificationService.showUnhandledToasterErrorMessage()
        }
        finally {
            void await this.dismissLoading()
        }
    }

    hasAnyCardAbleToBeActivated(): boolean {
        const cards = this.cacheService.getCards()
        return cards?.some(c => Card.isAbleToBeActivated(c))
    }

    hasActiveCards(): boolean {
        const cards = this.cacheService.getCards()
        return cards?.some(c => c.status == StatusEnum.Active)
    }

    isIntraFiEnabled(): boolean {
        return this.cacheService.getIsIntraFiEnabled()
    }

    private async presentLoading() {
        this.loading = await this.loadingController.create({
            message: this.translate.instant('commons.loading') as string
        })
        await this.loading.present()
    }

    private async dismissLoading() {
        await this.loading.dismiss()
    }
}

export class SlpMenu {
    label: string
    route: string
    active?: boolean
    children?: Array<SlpSubMenu>
    action?: () => unknown
}

export class SlpSubMenu {
    label: string
    route: string
}
