/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Inject, Injectable, Optional } from '@angular/core'
import { ApiServiceBase, IApiConfig } from '@sightline/models-misc'
import HTTPMethod from 'http-method-enum'
import { ActivateCard } from '../../../../src/app/card/activate/models/activate-card.model'
import { Card } from '../../../../src/app/card/activate/models/card.model'
import { SetPin } from '../../../../src/app/card/pin/models/set-pin.model'
import { Account } from '../../../../src/app/account/models/account.model'
import { Balance } from '../../../../src/app/account/models/balance.model'
import { TransactionFilter, TransactionInfoDto } from './models/transactionTableList.model'
import { IStatementFilterTable, StatementQueryResult } from './models/statementTableList.model'
import { FileDto, UpsertFileDto } from './models/file.model'
import { CRBConfigDto, CRBConfigSettingDto } from './models/configuration.model'
import { BankTransferModel } from './models/bank-transfer.model'
import { API_CONFIG } from '../../../../src/security/services/users/userApi.service'
import { CreateCard } from '../../../../src/app/card/activate/models/create-card.model'
import { PatronPreferenceDto } from './models/patron-preference'
import { BankTransferEnrollment } from '../../../../src/app/bank-transfer-enrollments/models/bank-transfer-enrollment'
import { Link2Wallet } from '../../../../src/commons/models/link-2-wallet'
import { CustomerNotificationOptOutDto } from '../../../../src/commons/models/customer-notification'
import { IcsStatus } from '../../../../src/app/intra-fi/models/ics-status'
import { GetIcsBanksResponsePayload, SetIcsBankExclusionsResponsePayload } from '../../../../src/app/intra-fi/models/ics-banks'

@Injectable()
export class ApiService {

  constructor(private serviceBase: ApiServiceBase, @Inject(API_CONFIG) @Optional() private config?: IApiConfig) { }

  public async getBrandFiles(originUrl: string, previewModel = false): Promise<FileDto[]> {
    if (previewModel) {
      return await this.serviceBase.callProtectedEndpoint<FileDto[]>(HTTPMethod.GET, `files/brand?domain=${originUrl}`, this.config)
        .toPromise()
    }
    else {
      return await this.serviceBase.callUnprotectedEndpoint<FileDto[]>(HTTPMethod.GET, `files/brand?domain=${originUrl}`, this.config)
        .toPromise()
    }
  }

  public async getCRBConfig(originUrl: string): Promise<CRBConfigDto> {
    return await this.serviceBase.callUnprotectedEndpoint<CRBConfigDto>(HTTPMethod.GET, `configuration/crb-config?domain=${originUrl}`, this.config)
      .toPromise()
  }

  public async getBrandConfigSettings(originUrl: string, previewModel: boolean): Promise<CRBConfigSettingDto[]> {
    if (previewModel) {
      return await this.serviceBase.callProtectedEndpoint<CRBConfigSettingDto[]>(HTTPMethod.GET, `configuration/crb-config/brand?domain=${originUrl}`, this.config)
        .toPromise()
    }
    else {
      return await this.serviceBase.callUnprotectedEndpoint<CRBConfigSettingDto[]>(HTTPMethod.GET, `configuration/crb-config/brand?domain=${originUrl}`, this.config)
        .toPromise()
    }
  }

  public async updateBrandFile(file: UpsertFileDto): Promise<number> {
    return await this.serviceBase.callProtectedEndpoint<number>(HTTPMethod.POST, 'files', this.config, file)
      .toPromise()
  }

  public async deleteBrandFile(fileRid: number): Promise<void> {
    return await this.serviceBase.callProtectedEndpoint<void>(HTTPMethod.DELETE, `files/${fileRid}`, this.config)
      .toPromise()
  }


  public async publishBrandConfiguration(crbConfigRId: number, programCode: string): Promise<boolean> {
    return await this.serviceBase.callProtectedEndpoint<boolean>(HTTPMethod.POST, 'preview/publish', this.config, {
      crbConfigRId: crbConfigRId,
      programCode: programCode,
    })
      .toPromise()
  }

  public async updateBrandConfigSettings(configSettings: { [key: string]: string }): Promise<void> {
    return await this.serviceBase.callProtectedEndpoint<void>(HTTPMethod.POST, 'configuration/crb-config', this.config, configSettings)
      .toPromise()
  }

  public async setLastLogin(): Promise<void> {
    return await this.serviceBase
      .callProtectedEndpoint<void>(HTTPMethod.PUT, 'users/last-login', this.config)
      .toPromise()
  }

  public async setPin(pinDto: SetPin): Promise<void> {
    await this.serviceBase
      .callProtectedEndpoint(HTTPMethod.POST, 'cards/pin', this.config, pinDto)
      .toPromise()
  }

  public async activateCard(activateCardRequest: ActivateCard): Promise<void> {
    await this.serviceBase
      .callProtectedEndpoint(HTTPMethod.POST, 'cards/activate', this.config, activateCardRequest)
      .toPromise()
  }

  public async createCard(createCardRequest: CreateCard): Promise<void> {
    await this.serviceBase
      .callProtectedEndpoint(HTTPMethod.POST, 'cards/create', this.config, createCardRequest)
      .toPromise()
  }

  public async getLink2Wallet(): Promise<Link2Wallet> {
    return await this.serviceBase
      .callProtectedEndpoint<Link2Wallet>(HTTPMethod.GET, 'cards/link2wallet', this.config)
      .toPromise()
  }

  public async getIsIntrafiEnabled(): Promise<boolean> {
    return await this.serviceBase
      .callProtectedEndpoint<boolean>(HTTPMethod.GET, 'ics-banks/is-intrafi-enabled', this.config)
      .toPromise()
  }

  public async getIsBanksList(): Promise<GetIcsBanksResponsePayload> {
    return await this.serviceBase
      .callProtectedEndpoint<GetIcsBanksResponsePayload>(HTTPMethod.GET, 'ics-banks/banks?getAll=true', this.config)
      .toPromise()
  }

  public async postExcludeIcsBanks(icsBanks: string[]): Promise<SetIcsBankExclusionsResponsePayload> {
    return await this.serviceBase
      .callProtectedEndpoint<SetIcsBankExclusionsResponsePayload>(HTTPMethod.POST, 'ics-banks/exclude', this.config, icsBanks)
      .toPromise()
  }

  public async getCards(cardReferenceNumber?: string): Promise<Array<Card>> {
    return await this.serviceBase
      .callProtectedEndpoint<Array<Card>>(HTTPMethod.GET, `cards${cardReferenceNumber ? ('?cardReferenceId=' + cardReferenceNumber) : ''}`, this.config,)
      .toPromise()
  }

  public async getCardFee(): Promise<number> {
    return await this.serviceBase
      .callProtectedEndpoint<number>(HTTPMethod.GET, 'cards/fee', this.config)
      .toPromise()
  }

  public async getIsBalanceFromOperator(): Promise<boolean> {
    return await this.serviceBase
      .callProtectedEndpoint<boolean>(HTTPMethod.GET, 'cards/is-balance-from-operator', this.config)
      .toPromise()
  }

  public async getAccount(): Promise<Account> {
    return await this.serviceBase
      .callProtectedEndpoint<Account>(HTTPMethod.GET, 'accounts', this.config)
      .toPromise()
  }

  public async getAccountsHistory(): Promise<Array<Account>> {
    return await this.serviceBase
      .callProtectedEndpoint<Array<Account>>(HTTPMethod.GET, 'accounts/history', this.config)
      .toPromise()
  }

  public async getBalance(cardReferenceNumber: string): Promise<Balance> {
    return await this.serviceBase
      .callProtectedEndpoint<Balance>(HTTPMethod.GET, `accounts/${cardReferenceNumber}/balance`, this.config)
      .toPromise()
  }

  public async getBankTransferIframeUrl(): Promise<BankTransferModel> {
    return await this.serviceBase
      .callProtectedEndpoint<BankTransferModel>(HTTPMethod.GET, 'bank-transfer/iframe-url', this.config)
      .toPromise()
  }

  public async getBankTransferEnrollments(): Promise<BankTransferEnrollment[]> {
    return await this.serviceBase
      .callProtectedEndpoint<BankTransferEnrollment[]>(HTTPMethod.GET, 'bank-transfer/enrollments', this.config)
      .toPromise()
  }

  public async getICSStatus(): Promise<IcsStatus> {
    return await this.serviceBase
      .callProtectedEndpoint<IcsStatus>(HTTPMethod.GET, 'ics-banks', this.config)
      .toPromise()
  }

  public async setICSStatus(icsOptOut: boolean): Promise<boolean> {
    return await this.serviceBase
      .callProtectedEndpoint<boolean>(HTTPMethod.POST, 'ics-banks/ics-status', this.config, icsOptOut)
      .toPromise()
  }


  public async getTransactionsQuery(cardReferenceNumber: string, payload: TransactionFilter): Promise<Array<TransactionInfoDto>> {
    return await this.serviceBase
      .callProtectedEndpoint<Array<TransactionInfoDto>>(
        HTTPMethod.POST,
        `cards/${cardReferenceNumber}/transactions/query`,
        this.config,
        payload
      )
      .toPromise()
  }

  public async getStatementsQuery(payload: IStatementFilterTable): Promise<StatementQueryResult> {
    return await this.serviceBase
      .callProtectedEndpoint<StatementQueryResult>(
        HTTPMethod.POST,
        'statements/query',
        this.config,
        payload
      )
      .toPromise()
  }

  public getStatementsDocumentUrl(cardReferenceNumber: string, statementId: string): string {
    return `${this.config?.url}/statements/${cardReferenceNumber}/${statementId}`
  }

  public async getPatronPreferences(cardReferenceNumber: string): Promise<Array<PatronPreferenceDto>> {
    return await this.serviceBase
      .callProtectedEndpoint<Array<PatronPreferenceDto>>(
        HTTPMethod.GET,
        `accounts/${cardReferenceNumber}/patron-preferences`,
        this.config
      )
      .toPromise()
  }

  public async updatetPatronPreference(cardReferenceNumber: string, patronPreference: PatronPreferenceDto): Promise<void> {
    await this.serviceBase
      .callProtectedEndpoint<Array<PatronPreferenceDto>>(
        HTTPMethod.POST,
        `accounts/${cardReferenceNumber}/patron-preferences`,
        this.config,
        patronPreference
      )
      .toPromise()
  }

  public async getCustomerNotifications(): Promise<Array<CustomerNotificationOptOutDto>> {
    return await this.serviceBase
      .callProtectedEndpoint<Array<CustomerNotificationOptOutDto>>(
        HTTPMethod.GET,
        'users/notifications',
        this.config
      )
      .toPromise()
  }

  public async postCustomerNotifications(customerNotifications: CustomerNotificationOptOutDto[]): Promise<void> {
    await this.serviceBase
      .callProtectedEndpoint<Array<CustomerNotificationOptOutDto>>(
        HTTPMethod.POST,
        'users/notifications',
        this.config,
        customerNotifications
      )
      .toPromise()
  }

  public async logChatInitiated(page: string): Promise<void> {
    await this.serviceBase
      .callProtectedEndpoint(HTTPMethod.POST, 'logs/chat', this.config, { 'page': page })
      .toPromise()
  }
}
