<app-chat [isUnaunthenticatedChat]="false"></app-chat>
<ion-toolbar>
  <div class="logos">
    <div class="custom-logo">
      <div class="brand-logo"
        [ngClass]="brandedBottomNavigationColorIsBrightness ? 'brand-logo-dark' : 'brand-logo-white'">
      </div>
    </div>
    <div class="footer-links">
      <ion-grid class="links-grid">
        <ion-row>
          <ion-col size="auto" *ngFor="let link of footerLinks">
            <a (click)="footerLinkClick(link)">{{ link.name }}</a>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
  <ion-row *ngIf="bankDisclosure">
    <div class="bank-disclosure-box">
      <div class="bank-disclosure">
        {{ bankDisclosure }}
      </div>
    </div>
  </ion-row>
  <ion-row *ngIf="intraFiDisclosure">
    <div class="intrafi-disclosure-box">
      <div class="intrafi-disclosure">
        <p [innerHTML]="intraFiDisclosure"></p>
      </div>
    </div>
  </ion-row>
</ion-toolbar>
<div class="version"> Version {{ appVersion }}  <span *ngIf="isPreviewMode"> | Preview Mode: TRUE</span></div>