/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { Component, OnInit } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { ModalController } from '@ionic/angular/standalone'
import { UtilsService } from '../services/business/utils.service'
import { CacheService } from '../services/business/cache.service'
import { Utils } from '../utils/utils'
import { TranslateService } from '@ngx-translate/core'
import { FooterLink, FooterLinkType } from '../models/footer-link.model'
import { HtmlContentModalComponent } from '../html-content-modal/html-content-modal.component'
import { CRBSettingKeys, IntraFiAgreement, PrivacyPolicy, WebsiteGlobalFooter } from '../services/api/models/configuration.model'
import { environment } from '../../../src/environments/environment'
import { HtmlPrivacyPolicyModalComponent } from '../html-privacy-policy-modal/html-privacy-policy-modal.component'


@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

    appVersion = environment.appVersion
    brandedBottomNavigationColorIsBrightness: boolean
    footerLinks: FooterLink[] = []
    bankDisclosure = ''
    intraFiDisclosure = ''
    isPreviewMode = false

    constructor(
        public utils: UtilsService,
        public cacheService: CacheService,
        public sanitizer: DomSanitizer,
        private translate: TranslateService,
        private modalController: ModalController
    ) {
        CacheService.brandConfigurationSettingsChanged.subscribe(() => {
            this.ngOnInit()
        })
    }

    ngOnInit(): void {
        this.footerLinks = []
        this.isPreviewMode = this.cacheService.getPreviewMode()
        this.footerLinks = []
        this.brandedBottomNavigationColorIsBrightness = UtilsService.brandedBottomNavigationColorIsBrightness

        const footerFiles = this.cacheService.getBrandConfigSettings().filter(x => WebsiteGlobalFooter.indexOf(x.keyName) >= 0)

        for (let i = 0; i < footerFiles.length; i++) {
            const footerFile = footerFiles[i]
            const footerLink = {
                id: footerFile.rId,
                key: footerFile.keyName,
                name: this.translate.instant(`commons.footer.${Utils.camelize(footerFile.keyName)}`) as string,
                url: footerFile.keyValue,
                type: FooterLinkType.HtmlContent
            } as FooterLink
            this.footerLinks.push(footerLink)
        }

        const footerPrivacyPolicy = this.cacheService.getBrandConfigSettings().filter(x => PrivacyPolicy.indexOf(x.keyName) >= 0)
        if (footerPrivacyPolicy && footerPrivacyPolicy.length > 0) {
            const footerLink = {
                id: 99,
                key: 'websitePrivacyNotice',
                name: this.translate.instant('commons.footer.websitePrivacyNotice') as string,
                url: null,
                type: FooterLinkType.PrivacyPolicy
            } as FooterLink
            this.footerLinks.push(footerLink)
        }

        const settings = this.cacheService.getBrandConfigSettings()
        this.bankDisclosure = settings?.find(q => q.keyName === CRBSettingKeys.BankDisclosure)?.keyValue
        this.intraFiDisclosure = settings?.find(q => q.keyName === CRBSettingKeys.IntraFiDisclosure)?.keyValue
    }

    footerLinkClick(footerLink: FooterLink): void {
        if (footerLink && footerLink.type == FooterLinkType.HtmlContent) {
            void this.displayModal(footerLink)
        } else if (footerLink && footerLink.type == FooterLinkType.PrivacyPolicy) {
            void this.displayPrivacyPolicyModal()
        }
    }

    async displayModal(footerLink: FooterLink): Promise<void> {
        const linkModal = await this.modalController.create({
            component: HtmlContentModalComponent,
            componentProps: {
                modalHtmlUrl: footerLink.url,
            },
            cssClass: 'html-modal',
        })
        await linkModal.present()
    }

    async displayPrivacyPolicyModal(): Promise<void> {
        const footerPrivacyPolicy = this.cacheService.getBrandConfigSettings().filter(x => PrivacyPolicy.indexOf(x.keyName) >= 0)
        const linkModal = await this.modalController.create({
            component: HtmlPrivacyPolicyModalComponent,
            componentProps: {
                privacyPolicyLinks: footerPrivacyPolicy
            },
            cssClass: 'html-modal',
        })
        await linkModal.present()
    }
}
